@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: "Staatliches";
    src: url(/static/media/Staatliches-Regular.a7f803c1.ttf);
  }

.a{color: #12c2e9}
.b{color: #12c2e9}
.c{color: #c471ed}
.d{color: #c471ed}
.e{color: #f64f59}
.f{color:  #f64f59}

.rainbow{
    background-color: #343A40;
    border-radius: 4px;  
    color: black;
    cursor: pointer;
    padding: 8px 16px;
}

.rainbow-dark {
    background-color: #AAAAAA;
    border-radius: 4px; 
    border-color: white !important; 
    color: white !important;
    cursor: pointer;
    padding: 8px 16px;
}
  
.rainbow-1:hover{
    background-image: linear-gradient(90deg, #12c2e9 0%, #c471ed 49%, #f64f59 80%, #12c2e9 100%);
    border: 1px solid linear-gradient(90deg, #12c2e9 0%, #c471ed 49%, #f64f59 80%, #12c2e9 100%) !important;
    animation:slidebg 5s linear infinite;
}

.rainbow-selection {
    background-image: linear-gradient(90deg, #12c2e9 0%, #c471ed 49%, #f64f59 80%, #12c2e9 100%);
    border: 1px solid linear-gradient(90deg, #12c2e9 0%, #c471ed 49%, #f64f59 80%, #12c2e9 100%) !important;
    animation:slidebg 5s linear infinite;
}

.App {
    text-align: center;
}

.App-logo {
    width: 20vw;
    border-radius: 50%;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        transition: all 200ms ease-in-out;
        overflow: hidden;
        display: inline-block;
        margin-bottom: 50px;
    }

    .App-logo:hover {
        transform: scale(1.01);
        border-radius: 20px;
    }

    .App-logo:after {
        content: "";
        position: absolute;
          top: -50%;
        left: -60%;
        width: 20%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);
      
        background: rgba(255, 255, 255, 0.13);
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.13) 77%,
          rgba(255, 255, 255, 0.5) 92%,
          rgba(255, 255, 255, 0.0) 100%
        );
      }
      
      /* Hover state - trigger effect */
      .App-logo:hover:after {
        opacity: 1;
        left: 130%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
      }
      
      /* Active state */
      .App-logo:active:after {
        opacity: 0;
      }
}

.logo-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    font-size: 6.5em;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -50px;
}

.App-header {
    min-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    font-weight: 200;
    color: black;
    transition: all 200ms linear;
}

.App-header-dark {
    min-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    font-weight: 200;
    color: #a4a7b1;
    transition: all 200ms linear;
}

@media (max-width: 991px) {
    .socials a {
        padding: 5vw !important;
    }

    .connect-wallet-button {
        font-size: 1.2em !important;
    }

    .App-logo {
        width: 75vw;
    }

    .ape-selectooor {
        width: 100px !important;
    }

    .top-left-logo {
        width: 20px !important;
    }

    .panel {
        margin-top: 40px !important;
    }

    html {
        overflow-y: scroll !important;
    }
}


  

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}

.socials {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.socials a {
    margin: 2vw;
}

.social-link {
    transition: all 500ms ease-in-out;
}

.social-link:hover {
    transform: scale(1.1) !important;
}

.coming-soon-text {
    font-family: 'Staatliches', sans-serif;
}

.coming-soon-text-dark {
    color: #a4a7b1;
}

.coming-soon-text a {
    color: green;
    text-decoration: none;
}

.countdown-text {
    font-size: 3em;
    margin-bottom: 20px;
    font-family: 'Staatliches', sans-serif;
}

.connect-wallet-button {
    background-color: transparent;
    border: 1px solid black;
    height: 2em;
    vertical-align: center;
    border-radius: 10px;
    color: black;
    padding: 0px 20px;
    font-size: 0.75em;
    transition: all 500ms ease-in-out;
    font-family: 'Staatliches', sans-serif;
}

.connect-wallet-button-dark {
    background-color: transparent;
    border: 1px solid white !important;
    height: 2em;
    vertical-align: center;
    border-radius: 10px;
    color: white;
    padding: 0px 20px;
    font-size: 0.75em;
    transition: all 500ms ease-in-out;
    font-family: 'Staatliches', sans-serif;
}

.min-width-200 {
    min-width: 200px;
}

@keyframes slidebg {
    to {
      background-position:20vw;
    }
  }

.account-connected {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 1em;
    font-family: 'Staatliches', sans-serif;
    transition: all 200ms linear;
}

.bolder {
    font-weight: 400;
}

.main-text-wrapper {
    background-color:rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    padding: 10px 10px;
    font-family: Staatliches;
}

.smaller-text {
    font-size: 0.64em;
}

.top-left {
    font-weight: 500;
    font-family: Staatliches;
    position: absolute;
    top: 5px;
    left: 15px;
}

.top-middle {
    font-weight: 500;
    font-family: Staatliches;
    position: absolute;
    top: 55px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    width: 300px;
}

.homepage-link {
    text-decoration: none;
    transition:all 200ms ease-in-out;
    color: #a4a7b1;
    vertical-align: middle;
    display: flex;
    align-items:center;
}

.no-text-dec {
    text-decoration: none;
    color: white;
}

.no-text-dec:hover {
    color:white;
}

.no-text-dec:active {
    color:white;
}

.homepage-link:hover {
    transition:all 200ms ease-in-out;
    background: linear-gradient(90deg, #12c2e9 0%, #c471ed 49%, #f64f59 80%, #12c2e9 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-text {
    background: linear-gradient(90deg, #12c2e9 0%, #c471ed 49%, #f64f59 80%, #12c2e9 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* .button-area {
    display: flex;
    align-items: center;
} */

.button-group-left {
    border-radius: 10px 0px 0px 10px !important;
    width: 150px;
}

.button-group-right {
    border-radius: 0px 10px 10px 0px  !important;
    width: 150px;
}

.button-group-center {
    border-radius: 0px 0px 0px 0px  !important;
}

.mb--20 {
    margin-bottom: 20px;
}

.mt--10 {
    margin-top: 10px !important;
}

.mb--10 {
    margin-bottom: 10px;
}

.panel {
    background-color:rgba(100, 100, 100, 0.8);
    border-radius: 20px;
    padding: 10px 10px;
    font-family: Staatliches;
}

.panel-header {
    color: white;
    border-radius: 20px 20px 0px 0px;
    background-color: rgba(0, 0, 0, 0.8);
    border-bottom: 1px dashed white;
}

.panel-body {
    border-radius: 0px 0px 20px 20px;
    border-bottom: 1px dashed white;
    border-left: 1px dashed white;
    border-right: 1px dashed white;
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.ape-selectooor {
    border-radius: 50%;
    width: 200px;
    transition: border-radius 200ms ease-in-out;
    cursor: pointer;
}

.ape-selectooor:hover {
    border-radius: 10px;
    transition: border-radius 200ms ease-in-out;
}

.ape-selected {
    border-radius: 10px;
    transition: border-radius 200ms ease-in-out;
    border: 3px dashed white;
}

.relative {
    position: relative;
}

.centered-loader {
    position: absolute;
    left: 0; 
    right: 0; 
    top: 40%;
    bottom: 0;
    margin: auto;
}

.mintnum-menu {
    font-size: 0.6em;
    padding: 5px 10px;
}

.trait-wrapper {
    font-size: 0.6em;
}

.left-align {
    text-align: left;
}

.that-font-i-like {
    font-family: 'Staatliches';
}

.oape-font {
    font-family: 'Poppins';
}

.top-left-logo {
    width: 50px;
}

.white {
    color: white;
}

.btn-disabled {
    color:rgba(100, 100, 100, 0.8) !important;
    border-color:rgba(100, 100, 100, 0.8) !important;
}

.btn-disabled:hover {
    color:rgba(100, 100, 100, 0.8) !important;
    border-color:rgba(100, 100, 100, 0.8) !important;
    background-image: none !important;
    cursor: auto;
}

.anime-animation {
    box-shadow:  0 0 0 #cccccc,
                   0 0 0 #ffffff,
                    10px 10px 10px #cccccc inset,
                    -10px -10px 10px #ffffff inset;
      animation: anime 3s cubic-bezier(0.16, 1, 0.3, 1) 1s infinite alternate;
}

.mutate-animation {
	animation: vibrate-3 0.5s linear infinite both;
}
  @keyframes vibrate-3 {
    0% {
      transform: translate(0);
              opacity: 1;
    }
    10% {
      transform: translate(-2px, -2px);
              opacity: 0.9;
    }
    20% {
      transform: translate(2px, -2px);
              opacity: 0.8;
    }
    30% {
      transform: translate(-2px, 2px);
              opacity: 0.7;
    }
    40% {
      transform: translate(2px, 2px);
              opacity: 0.6;
    }
    50% {
      transform: translate(-2px, -2px);
              opacity: 0.5;
    }
    60% {
      transform: translate(2px, -2px);
              opacity: 0.6;
    }
    70% {
      transform: translate(-2px, 2px);
              opacity: 0.7;
    }
    80% {
      transform: translate(-2px, -2px);
              opacity: 0.8;
    }
    90% {
      transform: translate(2px, -2px);
              opacity: 0.9;
    }
    100% {
      transform: translate(0);
              opacity: 1;
    }
  }
  

@keyframes anime {
    0% {
      width: 60px;
      height: 60px;
      background: #f0f0f0;
      box-shadow:  0 0 0 #cccccc,
                   0 0 0 #ffffff,
                    10px 10px 10px #cccccc inset,
                    -10px -10px 10px #ffffff inset;
    }
    25% {
      width: 60px;
      height: 60px;
      background: #f8f8f8;
      box-shadow:  10px 10px 10px #cccccc,
                   10px 10px 10px #ffffff,
                   0 0 0 #cccccc inset,
                   0 0 0 #ffffff inset;
    }
    50% {
      width: 60px;
      height: 240px;
      background: #f8f8f8;
      box-shadow:  10px 10px 10px #cccccc,
                   10px 10px 10px #ffffff,
                   0 0 0 #cccccc inset,
                   0 0 0 #ffffff inset;
    }
    100% {
      width: 480px;
      height: 240px;
      background: #fafafa;
      box-shadow:  40px 40px 40px #cccccc,
                   0 0 0 #ffffff,
                   0 0 0 #cccccc inset,
                   2px 2px 2px #ffffff inset;
    }
}

.spread-em {
    display: flex;
    justify-content: space-between;
}

.left-bottom-panel {
    align-items: flex-start;
    text-align: start;
    font-size: 0.75em;
    padding-left: 10px;
}

.right-bottom-panel {
    align-items: flex-end;
    text-align: end;
    font-size: 0.75em;
    padding-right: 10px;
}

.left-right-padding {
    padding-left: 10px;
    padding-right: 10px;
}
